<script>
import {getApplicantFullNAme, getCreatedAndUpdatedDates} from "@/helpers/functions";

export default {
  name: "funding-request-table",
  props: {
    fundingRequest: {
      type: Array,
      default(){
        return []
      }
    },
  },
  data(){
    return {
    }
  },
  methods: {
    getCreatedAndUpdatedDates,
    getApplicantFullNAme,
    openShowFundingRequest(fr){
      this.$emit('openShowFundingRequest', fr)
    },
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Montant (XAF)</th>
        <th scope="col">Demandeur</th>
        <th scope="col">Statut</th>
        <th scope="col">Notifications</th>
        <th scope="col">Date</th>
        <th scope="col">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(fr, index) in fundingRequest" :key="index">
        <th scope="row">{{index + 1}}</th>
        <td>{{ fr.amount?.toLocaleString() }}</td>
        <td><router-link :to="'/admin/members/' + fr.fundingRequestUser?.uniqId ">{{ getApplicantFullNAme(fr.applicant) }}</router-link></td>
        <td>
          <BBadge v-if="fr.status === 0" variant="dark">Créée</BBadge>
          <BBadge v-else-if="fr.status === 1" variant="warning">En Attente</BBadge>
          <BBadge v-else-if="fr.status === 2" variant="success">Accordée</BBadge>
          <BBadge v-else-if="fr.status === 3" variant="danger">Refusée</BBadge>
        </td>
        <td class="">
          <b-badge v-if="fr.unreadNotifications > 0" variant="danger">{{ fr.unreadNotifications }}</b-badge>
        </td>
        <td>{{ getCreatedAndUpdatedDates(fr.createdAt) }}</td>
        <td>
          <div class="hstack gap-3 fs-15">
            <router-link :to="'/admin/funding-requests/' + fr.uniqId" class="link-primary">
              <i class="ri-eye-fill"></i>
            </router-link>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>