<script>
import Layout from "../../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import DataLoader from "@/components/data-loader.vue";
import Pagination from "@/components/dashboard/Pagination.vue";
import FundingRequestTable from "@/components/dashboard/funding/request/FundingRequestTable.vue";
import Axios from "@/plugins/axios";
export default {
  name: "Request",
  components: {
    FundingRequestTable,
    Pagination,
    DataLoader,
    PageHeader,
    Layout
  },
  data() {
    return {
      fundingRequests: [],
      showDataLoader: true,
      activeProduct: null,
      next: null,
      previous: null,
      page: 1,
      totalItems: 0,
      query: '',
      url: ''
    }
  },
  mounted() {
    this.initUrl()
    this.getFundingRequests()
  },
  methods: {
    getFundingRequests(){
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(p => {
              this.fundingRequests.push(p)
            })

          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            this.showDataLoader = false
          })
    },
    initUrl(){
      this.url = 'admin/funding_requests?page=' + this.page + '&query=' + this.query
    },
    setPagination(response){
      if (response.data["hydra:view"]) {
        if (response.data["hydra:view"]["hydra:next"]){
          this.totalItems = response.data["hydra:totalItems"]
          this.next = response.data["hydra:view"]["hydra:next"]
          this.previous = response.data["hydra:view"]["hydra:previous"]
        }
      }
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.fundingRequests = []
      this.getFundingRequests()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.fundingRequests = []
      this.getFundingRequests()
    },
    navigate(page){
      this.page = page
      this.fundingRequests = []
      this.showDataLoader = true
      this.initUrl()
      this.getFundingRequests()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    },
    searchFundingRequest(searchBody){
      const {query} = searchBody
      this.query = query
      this.showDataLoader = true
      this.fundingRequests = []
      this.initUrl()
      this.getFundingRequests()
    },
  }
}
</script>
<template>
  <Layout>
    <PageHeader
        :show-add-button="false"
        :title="'Demande de financement'"
        @submitSearch="searchFundingRequest"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="" v-if="fundingRequests.length > 0">
        <div class="card">
          <div class="card-body">
            <FundingRequestTable
                :funding-request="fundingRequests"
            />
            <Pagination
                :next="next"
                :previous="previous"
                :currentPage="page"
                :totalItems="totalItems"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<style scoped>

</style>